import { zodResolver } from '@hookform/resolvers/zod'
import SendIcon from '@mui/icons-material/Send'
import { Stack, TextField, Button, CircularProgress } from '@mui/material'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@modules/Auth/hooks/useAuth'
import { useGetErrorMessage } from '@modules/Shared/hooks/useGetErrorMessage'
import { globalStore } from '@modules/Shared/store/globalStore'

import { SignInAuth, SignInAuthSchema } from '@context/Auth/domain/SignIn'

export const SignInForm = () => {
  const { openSnackbarAlert } = globalStore()
  const { t } = useTranslation(['common'])
  const { login } = useAuth()
  const [loading, setLoading] = useState(false)
  const { getErrorMessage } = useGetErrorMessage()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInAuth>({
    resolver: zodResolver(SignInAuthSchema),
  })

  const onSubmit = useCallback(
    async (data: SignInAuth) => {
      setLoading(true)
      const { error } = await login(data)
      if (error && openSnackbarAlert) {
        openSnackbarAlert({
          openSnackBar: true,
          severity: 'error',
          message: 'Solo se permiten usuarios administradores',
        })
      }
      setLoading(false)
    },
    [login, openSnackbarAlert],
  )

  return (
    <form>
      {loading && <CircularProgress />}
      <Stack spacing={2}>
        <TextField
          required
          id="email"
          type="email"
          label={t('email')}
          fullWidth
          {...register('email')}
          error={!!errors.email}
          helperText={getErrorMessage(errors.email) as string}
          autoComplete="email"
        />
        <TextField
          required
          id="password"
          label={t('password')}
          type="password"
          fullWidth
          {...register('password')}
          error={!!errors.password}
          helperText={getErrorMessage(errors.password)}
          autoComplete="current-password"
        />

        <Stack spacing={2} direction="row" justifyContent="right">
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => void handleSubmit(onSubmit)(event)}
            endIcon={<SendIcon />}
          >
            {t('signIn')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
