import { SignInAuth } from 'context/Auth/domain/SignIn'
import { decode } from 'jsonwebtoken'
import { getSession, signIn } from 'next-auth/react'

import { LoggedUserInfoSchema } from '@context/Auth/domain/LoggedUserInfo'

export const useAuth = () => {
  const login = async ({ email, password }: SignInAuth) => {
    const result = await signIn('credentials', { username: email, password: password, redirect: false })

    return { error: result?.error }
  }

  const getLoggedUser = async () => {
    const session = await getSession()

    if (session?.id) {
      const userInfo = decode(session.id as string)

      if (userInfo !== null) return LoggedUserInfoSchema.parse(userInfo)
    }

    return null
  }

  return {
    login,
    getLoggedUser,
  }
}
