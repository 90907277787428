import { z } from 'zod'

export const SignInAuthSchema = z.object({
  email: z
    .string()
    .email({
      message: 'common:loginErrorInvalidEmail',
    })
    .min(1, { message: 'common:requiredField' }),
  password: z.string(),
})

export type SignInAuth = z.infer<typeof SignInAuthSchema>
