import { z } from 'zod'

export const LoggedUserInfoSchema = z.object({
  aud: z.string(),
  auth_time: z.number(),
  'cognito:username': z.string(),
  'custom:sav_role_name': z.string(),
  'custom:sav_installation_id': z.string().optional(),
  'custom:sav_user_id': z.string(),
  email: z.string(),
  email_verified: z.boolean(),
  event_id: z.string(),
  exp: z.number(),
  iat: z.number(),
  iss: z.string(),
  jti: z.string(),
  name: z.string(),
  origin_jti: z.string(),
  phone_number: z.string().optional(),
  phone_number_verified: z.string().optional(),
  sub: z.string(),
  token_use: z.string(),
})

export type LoggedUserInfo = z.infer<typeof LoggedUserInfoSchema>
