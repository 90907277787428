import { ParseKeys } from 'i18next'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const useGetErrorMessage = () => {
  const { t } = useTranslation(['common'])

  const getErrorMessage = (fieldError: FieldError | undefined) =>
    fieldError?.message ? t(fieldError.message as ParseKeys) : false

  return {
    getErrorMessage,
  }
}
