import { Box, Grid } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { ReactElement, useEffect } from 'react'

import { SignInForm } from '@modules/Auth/components/SignInForm'

import logo from '@public/img/savioo-logo.png'

export default function SignIn() {
  const router = useRouter()
  const { status } = useSession()

  useEffect(() => {
    if (status === 'authenticated') {
      void router.push('/installations')
    }
  }, [status, router])

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        sx={{
          height: '100vh',
        }}
      >
        <Grid
          item
          md={6}
          xs={0}
          sx={{
            background: 'no-repeat center center url(/img/sign-in.jpg)',
            backgroundSize: 'cover',
          }}
        ></Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            sx={{
              height: '100%',
            }}
          >
            <Grid item md={8} lg={6} xs={10}>
              <Box mb={4} justifyContent="center" sx={{ display: 'flex' }}>
                <Image src={logo} alt="Savioo" priority width={300} />
              </Box>
              <SignInForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

SignIn.getLayout = function getLayout(page: ReactElement) {
  return <Box>{page}</Box>
}
